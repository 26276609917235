import { Alert, Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colorOrange } from "../../../config/color";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { sessionEnd } from "../../../redux/actions";
import { IClient } from "../../../interface/client";
import { getAll } from "../../../fetchs/get";

const SubcontractInfosFields = ({
  formik
} : {
  formik: any
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [suppliers, setSuppliers] = useState<IClient[]>([]);
  const [isRealProject, setIsRealProject] = useState<boolean>();
  const [isRealSupplier, setIsRealSupplier] = useState<boolean>();
  const [projects, setProjects] = useState<any>([]);
  
  const [loading, setLoading] = useState<boolean>(false);

  const getAllSuppliers = useCallback(() => {
    if (token) {
      getAll(token, "/suppliers/", setSuppliers, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  const supplierActeam = suppliers.map((client: IClient) => ({
    _id: client._id,
    name: client.name,
  })) as unknown as readonly string[];
  

  useEffect(() => {
    getAllSuppliers();
  }, [getAllSuppliers]);

  const selectInputs = [
    { id: "typeFrais", options: ["Forfait", "Régie"], value: formik.values.typeFrais || null, label: "Type de frais", touched: formik.touched.typeFrais, errors: formik.errors.typeFrais, required: true },
    { id: "supplier", options: supplierActeam, value: supplierActeam.find((supplier: any) => supplier._id === formik.values.supplier ) || null, label: "Fournisseur", touched: formik.touched.supplier, errors: formik.errors.supplier, required: true },
    { id: "agency", options: ["Toutes", "Nord", "Sud"], value: formik.values.agency || null, label: "Agence", touched: formik.touched.agency, errors: formik.errors.agency, required: false },
  ];
  

  const handleFetch = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/suppliers/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await getAllSuppliers();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des fournisseurs.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${urlAPI}/projects/dolibarr/getAllProjects`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProjects(response.data);
    } catch (error: any) {
      console.log(error.response.data.error);
    }
  };

  useEffect(() => {
    setIsRealProject(formik.values.project?.includes("PJ") ? true : false);
  }, [formik.values.project])

  useEffect(() => {
    setIsRealSupplier(formik.values.supplier ? true : false);
  }, [formik.values.supplier])

  useEffect(() => {
    if (isRealProject) {
      fetchProjects();
    }
  }, [isRealProject]);

  useEffect(() => {
    isRealSupplier ? formik.setFieldValue("supplier", "") : formik.setFieldValue("fictifSupplier", "");
  }, [isRealSupplier])

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
        {
            selectInputs.map((select) => {
              return (
                <>
                  {select.id === "supplier" &&
                    <>
                      <Box sx={{display : "flex", justifyContent: "center"}}>
                        <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealProject ? "bold" : ""}}>Projet Fictif</Typography>
                        <Switch
                          checked={isRealProject}
                          onChange={() => setIsRealProject(!isRealProject)}
                        />
                        <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealProject ? "bold" : ""}}>Projet Réel</Typography>
                      </Box>
          
          
                      {isRealProject ? (
                        <Autocomplete
                          options={projects}
                          getOptionLabel={(option: any) => `${option.ref} - ${option.title}`} // Affiche ref + title
                          isOptionEqualToValue={(option: any, value: any) => {
                            if (!value || typeof value !== "string") return false; // Vérifie que value est bien une string
                            return option.ref === value.split(" - ")[0]; // Compare la ref
                          }}
                          
                          value={projects.find((p: any) => `${p.ref} - ${p.title}` === formik.values.project) || null} // Recherche via concat
                          onChange={(event, value: any) => {
                            formik.setFieldValue("project", value ? `${value.ref} - ${value.title}` : ""); // Stocke ref - title
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              margin="normal"
                              label="Projet associé"
                              error={formik.touched.project && Boolean(formik.errors.project)}
                              helperText={formik.touched.project && formik.errors.project}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          margin="normal"
                          required
                          type="text"
                          id="project"
                          label="Projet associé"
                          name="project"
                          autoComplete="project"
                          value={formik.values.project}
                          onChange={formik.handleChange}
                          error={formik.touched.project && Boolean(formik.errors.project)}
                          helperText={formik.touched.project && formik.errors.project}
                          autoFocus
                        />
                      )}
                    </>
                  }
                  {select.id === "supplier" &&
                    <>
                      <Box sx={{display : "flex", justifyContent: "center"}}>
                        <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealSupplier ? "bold" : ""}}>Fournisseur Fictif</Typography>
                        <Switch
                          checked={isRealSupplier}
                          onChange={() => setIsRealSupplier(!isRealSupplier)}
                        />
                        <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealSupplier ? "bold" : ""}}>Fournisseur Réel</Typography>
                      </Box>
                      {isRealSupplier ? (
                        <Autocomplete
                          options={supplierActeam}
                          getOptionLabel={(option: any) => `${option.name}`}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return option._id === value._id; 
                          }}
                          value={supplierActeam.find((supplier: any) => supplier._id === formik.values.supplier)}
                          onChange={(event, value: any) => {
                            formik.setFieldValue("supplier", value ? `${value._id}` : "");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              margin="normal"
                              label="Fournisseur Réel"
                              error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                              helperText={formik.touched.supplier && formik.errors.supplier}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          margin="normal"
                          required
                          type="text"
                          id="fictifSupplier"
                          label="Fournisseur fictif"
                          name="fictifSupplier"
                          autoComplete="fictifSupplier"
                          value={formik.values.fictifSupplier}
                          onChange={formik.handleChange}
                          error={formik.touched.fictifSupplier && Boolean(formik.errors.fictifSupplier)}
                          helperText={formik.touched.fictifSupplier && formik.errors.fictifSupplier}
                          autoFocus
                        />
                    )}
                    </>
                  }
                  {select.id !== "supplier" &&
                    <Autocomplete
                      key={select.id}
                      id={select.id}
                      options={select.options}
                      getOptionLabel={(option: any) =>select.id === "supplier" ? `${option.name || ""}`.trim() : option }
                      isOptionEqualToValue={(option: any, value: any) => select.id === "supplier" ? option._id === value?._id : option === value}
                      value={select.value}
                      onChange={(event, value: any) => {
                          formik.setFieldValue(
                            select.id,
                            value ? (select.id === "supplier" ? value._id : value) : ""
                          );
                      }}
                      renderInput={(params) => (
                          <TextField
                            {...params}
                            required={select.required}
                            name={select.id}
                            margin="normal"
                            label={select.label}
                            error={select.touched && Boolean(select.errors)}
                            helperText={select.touched && select.errors}
                          />
                      )}
                    />
                  }
                  {select.id === "supplier" && 
                    <TextField
                      margin="normal"
                      required
                      type="text"
                      id="label"
                      label="Libellé du frais"
                      name="label"
                      autoComplete="label"
                      value={formik.values.label}
                      onChange={formik.handleChange}
                      error={formik.touched.label && Boolean(formik.errors.label)}
                      helperText={formik.touched.label && formik.errors.label}
                      autoFocus
                    />
                  }
                </>
                
              )
            })
        }
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
        {loading ? (
            <CircularProgress sx={{ margin: "16px auto" }} />
        ) : (
            <>
                <Button
                    onClick={handleFetch}
                    fullWidth
                    sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
                >
                    {"Mettre à jour la liste des fournisseurs"}
                </Button>
            </>
        )}
        </Box>
    </>
  )
}

export default SubcontractInfosFields;