import { DataGrid, getGridStringOperators, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ITurnover } from "../../../interface/turnover";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import getTurnoverData from "../../../tools/forecast/getTurnoverData";
import { useParams } from "react-router";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { formattedPrice } from "../../function/formattedPrice";

const TurnoverData = ({
    forecastVersion,
    forecastDates,
    type,
    agency,
    setSelectedTurnover,
    openModal,
    showDeleteDialog,
    setOpenModal,
    setSelectedData,
    selectedData,
    setEditId,
    projectRegex,
    collabRegex,
    clientRegex,
    labelRegex
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    type: string,
    agency: string | null,
    setSelectedTurnover: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setOpenModal: Function,
    setSelectedData: Function,
    selectedData: string[],
    setEditId: Function,
    projectRegex : string,
    collabRegex : string,
    clientRegex : string,
    labelRegex : string
}) => {

    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [turnoverData, setTurnoverData] = useState<ITurnover[]>([]);
    const monthList = getForecastMonthList(forecastDates);
    const [selected, setSelected] = useState<GridRowId[]>([]);

    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        getTurnoverData(params.id, forecastVersion, agency, type, setTurnoverData, token, dispatch, setError);
    }, [agency, forecastVersion, openModal, showDeleteDialog, selectedData]);

    const handleRowSelectionChange = (newSelection: any) => {
        const newSelectedTurnover = { [type]: newSelection };
        setSelectedTurnover((prevSelectedTurnover: any) =>
            Object.assign({}, prevSelectedTurnover, newSelectedTurnover)
        );
    };

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label"},
            { name: "Client", field: "client" },
            { name: type === "Régie" ? "Collaborateur" : "Projet", field: type === "Régie" ? "collab" : "project" },
            { name: "Agence", field: "agency" },
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => ({
        field: column.field,
        headerName: column.name,
        filterable: true,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
        headerClassName: "custom-header"
    }));


    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            align: 'center',
            headerAlign: 'center',
            headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    let totalByMonth = {};

    useEffect(() => {
        const turnoverRows: GridRowsProp = turnoverData
        .filter((turnover) => turnover.client && turnover.client.name.toLowerCase().includes(clientRegex || ""))
        .filter((turnover) => {
            return (
                (turnover.label && turnover.label.includes(labelRegex || "")) ||
                (!turnover.label && !labelRegex)
            )
        })
        .filter((turnover) => {
            if (type === "Régie") {
                if(!projectRegex){
                    return (
                        (turnover.collab && (turnover.collab.firstname + " " + turnover.collab.lastname).toLowerCase().includes(collabRegex || "")) ||
                        (turnover.fictifCollab && turnover.fictifCollab.toLowerCase().includes(collabRegex || "")) ||
                        (turnover.collab === null && turnover.fictifCollab === undefined)
                    );
                }
            } else {
                return !collabRegex && turnover.project.toLowerCase().includes(projectRegex || "");
            }
        })
        .map((turnover: ITurnover) => {
            const turnoverRow: any = {
                id: turnover._id,
                agency: turnover.agency ? turnover.agency : "Non précisée",
                client: turnover.client.name,
                label: turnover.label || "Non précisé"
            };
    
            let monthExpenses;
            if (turnover.type === "Régie") {
                turnoverRow["collab"] = (
                    (turnover.collab ? (turnover.collab.firstname + " " + turnover.collab.lastname) : "") || 
                    turnover.fictifCollab || 
                    "Non précisé"
                ).toUpperCase();
                monthExpenses = Object.fromEntries(
                    Object.entries(turnover.months).map(([key, value]) => [value.label, 
                        `Nb JO : ${value.openDays}
                        TJM : ${value.tjm} €/JO
                        Total : ${value.openDays * value.tjm} €`
                    ])
                );
            }
            else {
                turnoverRow["project"] = turnover.project ? turnover.project : "Non précisé";
                
                monthExpenses = Object.fromEntries(
                    Object.entries(turnover.months).map(([key, value]) => [value.label, `\n${formattedPrice(value.amount)} €\n\u000A`])
                );
            }
            return { ...turnoverRow, ...monthExpenses };
        });
    
        totalByMonth = monthList.reduce((acc: any, item: any) => {
            acc[item.field] = turnoverData.reduce((sum: number, turnover: any) => {
                if(
                    (turnover.type !== "Régie" ? (
                        !collabRegex && turnover.project && turnover.project.toLowerCase().includes(projectRegex || "")
                    ) : (
                        !projectRegex && (
                            (turnover.collab && (turnover.collab.firstname + " " + turnover.collab.lastname).toLowerCase().includes(collabRegex || "")) ||
                            (turnover.fictifCollab && turnover.fictifCollab.toLowerCase().includes(collabRegex || ""))
                        )
                    )) 
                    && (turnover.client && turnover.client.name.toLowerCase().includes(clientRegex || "")) 
                    && (
                        (turnover.label && turnover.label.includes(labelRegex || "")) ||
                        (!turnover.label && !labelRegex)
                    )
                ){
                    const matchingMonth = turnover.months.find((month: any) => month.label === item.field);
                    if (matchingMonth) {
                        if (turnover.type === "Régie") {
                            return sum + (matchingMonth.openDays * matchingMonth.tjm || 0);
                        }
                        else {
                            return sum + (matchingMonth.amount || 0);
                        }
                    }
                }
                else{
                    return sum;
                }
            }, 0);
            return acc;
        }, {})

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );

        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            collab: `\nTOTAL ${type.toUpperCase()}\n\u000A`,
            project: `\nTOTAL ${type.toUpperCase()}\n\u000A`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `${formattedPrice(value as number)} €`])
            )
        } : null;

        setRows(turnoverData.length > 0 && totalRow ? [...turnoverRows, totalRow] : [...turnoverRows])
        dispatch(setTotalByMonth(type, totalByMonth));

    }, [turnoverData, projectRegex, clientRegex, collabRegex, labelRegex])


    if (turnoverData.length > 0) {
        return (
            <>
                <Typography variant="h5" sx={{ textAlign: "center", backgroundColor: "black", color: "white" }}>{type}</Typography>
                <CustomDataGrid
                    disableRowSelectionOnClick={true}
                    rows={rows}
                    columns={columns}
                    onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                    checkboxSelection
                    rowSelectionModel={selectedData}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelected(newSelection);
                        handleRowSelectionChange(newSelection);
                        setSelectedData(newSelection);
                    }}
                    isRowSelectable={(params) => {
                        return params.row.id !== 'total';
                    }}
                    tableName={`CA_${type.replace("/", "_")}`}
                />
            </>
        )
    }
    else {
        return <></>
    }
}
export default TurnoverData;