export const formattedPrice = (value: number | string) => {
  if (typeof value === 'string') {
      value = value.replace(/[€\s]/g, '').replace(',', '.');
  }
  
  const num = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(num)) return '';
  if (num === 0) return '0';
  
  const isNegative = num < 0;
  const roundedNum = Math.abs(Math.round(num * 100) / 100);
  const [intPart, decPart] = roundedNum.toString().split('.');
  
  const formattedInt = intPart
      .split('')
      .reverse()
      .reduce((acc, digit, i) => {
          if (i > 0 && i % 3 === 0) return digit + ' ' + acc;
          return digit + acc;
      }, '');
  
  return decPart ? `${isNegative ? '-' : ''}${formattedInt},${decPart}` : `${isNegative ? '-' : ''}${formattedInt}`;
};