import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ICompany } from "../../../interface/company";
import getCompanyData from "../../../tools/forecast/getCompanyData";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { formattedPrice } from "../../function/formattedPrice";

const CompanyData = ({
    forecastVersion,
    forecastDates,
    selectedCompany,
    setSelectedCompany,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal,
    projectRegex,
    supplierRegex
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    selectedCompany: string[],
    setSelectedCompany: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setEditId: Function,
    setOpenModal: Function,
    projectRegex: string,
    supplierRegex: string
}) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [companyData, setCompanyData] = useState<any[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        getCompanyData(params.id, forecastVersion, setCompanyData, token, dispatch, setError);
    }, [forecastVersion, openModal, showDeleteDialog, selectedCompany]);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
            { name : "Fournisseur", field : "supplier"},
            { name : "Projet", field : "project"}
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: "custom-header",
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {params.value?.split('\n').map((line: any, index: any) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            ),
        }
    })

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    let totalByMonth = {};

    useEffect(() => {
        const turnoverRows: GridRowsProp = companyData
        .filter((tns) => tns.project && tns.project.toLowerCase().includes(projectRegex || ""))
        .filter((tns) => 
            (tns.supplier && tns.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
            (tns.fictifSupplier && tns.fictifSupplier.includes(supplierRegex || "")) ||
            (!supplierRegex && !tns.supplier && !tns.fictifSupplier)
        )
        .map((tns: ICompany) => {
            const turnoverRow: any = {
                id: tns._id,
                label: `\n${tns.label}\n\u000A`,
                supplier: `\n${tns.supplier?.name || tns.fictifSupplier || "Non précisé"}\n\u000A`,
                project: `\n${tns.project || "Non précisé"}\n\u000A`
            };
    
            const monthExpenses = Object.fromEntries(
                Object.entries(tns.months).map(([key, value]) => {
                    if (typeof value === "object" && value !== null) {
                        const obj = value as { label: string; value: number };
                        return [obj.label, `\n${formattedPrice(obj.value as number)} €\n`];
                    }
                    return [key, `\n${formattedPrice(value)} €\n`];
                })
            );
    
            return { ...turnoverRow, ...monthExpenses };
        });
    
    
    
        totalByMonth = monthList.reduce((acc: any, item: any) => {
            acc[item.field] = companyData.reduce((sum, data) => {
                if(
                    (!data.project || data.project.toLowerCase().includes(projectRegex || "")) &&
                    (
                        (data.supplier && data.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
                        (data.fictifSupplier && data.fictifSupplier.includes(supplierRegex || "")) ||
                        (!supplierRegex && !data.supplier && !data.fictifSupplier)
                    )
                ){
                    const monthData = data.months.find((month: any) => month.label === item.field);
                    return sum + (monthData ? monthData.value : 0);
                }
                else{
                    return sum;
                }
            }, 0);
            return acc;
        }, {});

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );
    
        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            label: `\nTOTAL\n\u000A`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `\n${formattedPrice(value as number)} €\n\u000A`])
            )
        } : null;

        setRows(companyData.length > 0 && totalRow ? [...turnoverRows, totalRow] : [...turnoverRows]);

        dispatch(setTotalByMonth("Entreprise", totalByMonth));
    }, [companyData, projectRegex, supplierRegex])

    return (
        <>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                checkboxSelection
                rowSelectionModel={selectedCompany}
                onRowSelectionModelChange={(selectionModel) => { setSelectedCompany(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                tableName="Company"
            />
        </>
    )
}

export default CompanyData;