import React, { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography
} from '@mui/material';
import { CustomDataGridProps } from './CustomDataGrid.props';
import "./CustomDataGrid.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import axios from 'axios';
import { urlAPI } from '../../config/config';
const CustomDataGrid: React.FC<CustomDataGridProps> = ({
    columns,
    rows,
    checkboxSelection = false,
    disableRowSelectionOnClick = false,
    getRowHeight = () => 'auto',
    rowSelectionModel,
    onRowSelectionModelChange,
    isRowSelectable,
    onCellClick,
    stickyColumn,
    sx,
    tableName,
    hideFooter
}) => {
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let currentUser = useSelector((state: RootState) => {
        return state.user.username;
    });

    const [sortModel, setSortModel] = useState<{ field: string; sort: 'asc' | 'desc' } | null>(null);
    const [internalSelectedRows, setInternalSelectedRows] = useState<(string | number)[]>([]);
    const selectedRows = rowSelectionModel !== undefined ? rowSelectionModel : internalSelectedRows;

    const mutableRows = [...rows];

    if (sortModel) {
        mutableRows.sort((a, b) => {
            let aVal = a[sortModel.field];
            let bVal = b[sortModel.field];
            const col = columns.find(c => c.field === sortModel.field);

            if (col?.sortComparator) {
                return sortModel.sort === 'asc'
                    ? col.sortComparator(aVal, bVal, a, b)
                    : -col.sortComparator(aVal, bVal, a, b);
            }

            if (typeof aVal === 'number' && typeof bVal === 'number') {
                return sortModel.sort === 'asc' ? aVal - bVal : bVal - aVal;
            }

            aVal = aVal ? String(aVal) : '';
            bVal = bVal ? String(bVal) : '';
            return sortModel.sort === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
        });
    }

    const normalRows = mutableRows.filter(row => row.id !== 'total');
    const totalRow = mutableRows.find(row => row.id === 'total');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const paginatedRows = normalRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    const displayRows = totalRow ? [...paginatedRows, totalRow] : paginatedRows;

    useEffect(() => {
        if(tableName) handleGetRows(tableName);
    }, [currentUser, token, tableName])

    const handleSetRows = async (rowsNumber : number, tableName? : string) => {
        const response = await axios.post(`${urlAPI}/forecast/tableRows`,
            { rows : rowsNumber, tableName, user: currentUser},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
    }

    const handleGetRows = async (tableName : string) => {
        const syncResponse = await axios.get(`${urlAPI}/forecast/tableRows/${currentUser}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
        );
        if (syncResponse.data.message.length > 0) setRowsPerPage(syncResponse.data.message[0][tableName]);
    }

    const toggleSort = (field: string) => {
        if (sortModel && sortModel.field === field) {
            setSortModel({ field, sort: sortModel.sort === 'asc' ? 'desc' : 'asc' });
        } else {
            setSortModel({ field, sort: 'asc' });
        }
    };

    const handleRowClick = (row: any) => {
        if (disableRowSelectionOnClick) return;
        if (isRowSelectable && !isRowSelectable({ row })) return;
        if (row.id === 'total') return;
        const id = row.id;
        let newSelection: (string | number)[] = [];
        if (selectedRows.includes(id)) {
            newSelection = selectedRows.filter(item => item !== id);
        } else {
            newSelection = [...selectedRows, id];
        }
        onRowSelectionModelChange ? onRowSelectionModelChange(newSelection) : setInternalSelectedRows(newSelection);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
        e.stopPropagation();
        const id = row.id;
        let newSelection: (string | number)[] = [];
        if (e.target.checked) {
            newSelection = [...selectedRows, id];
        } else {
            newSelection = selectedRows.filter(item => item !== id);
        }
        onRowSelectionModelChange ? onRowSelectionModelChange(newSelection) : setInternalSelectedRows(newSelection);
    };

    const handleHeaderCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const selectableIds = paginatedRows
                .filter(row => !isRowSelectable || isRowSelectable({ row }))
                .map(row => row.id);
            const newSelection = [...selectedRows, ...selectableIds.filter(id => !selectedRows.includes(id))];
            onRowSelectionModelChange ? onRowSelectionModelChange(newSelection) : setInternalSelectedRows(newSelection);
        } else {
            const deselectedIds = paginatedRows
                .filter(row => !isRowSelectable || isRowSelectable({ row }))
                .map(row => row.id);
            const newSelection = selectedRows.filter(id => !deselectedIds.includes(id));
            onRowSelectionModelChange ? onRowSelectionModelChange(newSelection) : setInternalSelectedRows(newSelection);
        }
    };

    const headerCheckboxChecked = paginatedRows.every(row =>
        (!isRowSelectable || isRowSelectable({ row })) && selectedRows.includes(row.id)
    );
    const headerCheckboxIndeterminate =
        paginatedRows.some(row => (!isRowSelectable || isRowSelectable({ row })) && selectedRows.includes(row.id)) &&
        !headerCheckboxChecked;

    const handleCellClick = (row: any, field: string) => {
        if (onCellClick) {
            onCellClick({ row, field, value: row[field] });
        }
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        handleSetRows(parseInt(event.target.value, 10), tableName);
        setPage(0);
    };

    return (
        <Box sx={sx} className="kairos-container">
            <TableContainer className="kairos-table-container">
                <Table size="small" className="kairos-table">
                    <TableHead className="kairos-table-head">
                        <TableRow>
                            {checkboxSelection && (
                                <TableCell className="kairos-checkbox-cell">
                                    {displayRows.length > 0 ? (
                                        <Checkbox
                                            size="small"
                                            checked={headerCheckboxChecked}
                                            indeterminate={headerCheckboxIndeterminate}
                                            onChange={handleHeaderCheckboxChange}
                                        />
                                    ) : null}
                                </TableCell>
                            )}
                            {columns.map(col => (
                                <TableCell
                                    key={col.field}
                                    onClick={() => toggleSort(col.field)}
                                    className={col.field === stickyColumn ? "kairos-sticky-cell kairos-column-header" : "kairos-column-header"}
                                >
                                    {col.headerName ?? ''}
                                    {sortModel?.field === col.field && (sortModel?.sort === 'asc' ? <span className='arrow-up'>↑</span> : <span className='arrow-down'>↓</span>)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody className="kairos-table-body">
                        {displayRows.length > 0 ? (
                            displayRows.map(row => (
                                <TableRow
                                    key={row.id}
                                    onClick={row.id === 'total' ? undefined : () => handleRowClick(row)}
                                    className="kairos-row"
                                >
                                    {checkboxSelection && (
                                        <TableCell className="kairos-checkbox-cell">
                                            {isRowSelectable && !isRowSelectable({ row }) ? null : (
                                                row.id !== "total" ? (
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.id)}
                                                        onChange={(e) => handleCheckboxChange(e, row)}
                                                        onClick={(e) => e.stopPropagation()}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )
                                            )}
                                        </TableCell>
                                    )}


                                    {columns.map(col => (
                                        <TableCell 
                                            key={col.field} 
                                            className={col.field === stickyColumn ? "kairos-sticky-cell kairos-table-cell" : "kairos-table-cell"}
                                            onClick={row.id === 'total' ? undefined : () => handleCellClick(row, col.field)}
                                        >
                                            <div dangerouslySetInnerHTML={{ 
                                                __html: row[col.field]?.replace(/(--\s*.*?\s*--)/g, "<b>$1</b>").replace(/\n/g, "<br />") 
                                            }}></div>
                                        </TableCell>

                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={checkboxSelection ? columns.length + 1 : columns.length}
                                    className="kairos-no-results"
                                    align="center"
                                >
                                    Pas de résultats
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>


                </Table>
            </TableContainer>
            {!hideFooter &&
                <TablePagination
                    component="div"
                    count={normalRows.length}
                    page={page}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="kairos-pagination"
                />
            }
        </Box>
    );
};

export default CustomDataGrid;
