import { Box, Button, Dialog, DialogTitle, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getGridStringOperators, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import getFeesData from "../../../tools/forecast/getFeesData";
import { IFees } from "../../../interface/fees";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import AddUpdateFee from "../AddUpdate/AddUpdateFee";
import CloseIcon from "@mui/icons-material/Close";
import { addFee, handleUpdateFee } from "../../../tools/forecast/addUpdateFees";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import { formattedPrice } from "../../function/formattedPrice";

const Fees = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [actionType, setActionType] = useState<"delete" | "close" | null>(null);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("");

    const [error, setError] = useState("");
    const [feesData, setFeesData] = useState<IFees[]>([]);
    const params = useParams();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedFee, setSelectedFee] = useState<IFees | null>(null);
    const [edit, setEdit] = useState<boolean>(false);
    const [agency, setAgency] = useState<string | null>("All");
    const agencies = [
        { label: "Toutes les agences", value: "All" },
        { label: "Agence Nord", value: "Nord" },
        { label: "Agence Sud", value: "Sud" }
    ];
    const [selectedFees, setSelectedFees] = useState<GridRowId[]>([]);

    useEffect(() => {
        getFeesData(params.id, forecastVersion, agency, setFeesData, token, dispatch, setError);
    }, [agency, forecastVersion, openModal]);

    const monthList = getForecastMonthList(forecastDates);

    const [projectRegex, setProjectRegex] = useState<string>("");
    const [domainRegex, setDomainRegex] = useState<string>("");
    const [collabRegex, setCollabRegex] = useState<string>("");

    const [rows, setRows] = useState<GridRowsProp>([]);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
            { name: "Projet", field: "project" },
            { name: "Bénéficiaire", field: "beneficiary" },
        ],
        months: monthList
    };

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: column.field === "label" ? "sticky-column" : "",
            cellClassName: column.field === "label" ? "sticky-column" : "",
        };
    });

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 120,
            headerClassName: "custom-header",
        };
    });

    const columns: GridColDef[] = [

        ...columnsInfos,
        ...columnsMonth,
    ];

    let totalByMonth = {};

    useEffect(() => {
        const feesRows: GridRowsProp = feesData
        .filter((fee) => (fee.beneficiary.firstname + " " + fee.beneficiary.lastname).toLowerCase().includes(collabRegex || ""))
        .filter((fee) => 
            (fee.project && fee.project.toLowerCase().includes(projectRegex || "")) ||
            (!projectRegex && !fee.project)
        )
        .filter((fee) => 
            (!domainRegex && !fee.domain) || 
            (fee.domain && fee.domain.toLowerCase().includes(domainRegex || ""))
        )
        .map((fee: IFees) => {
            const beneficiaryName = fee.beneficiary
                ? `${fee.beneficiary.firstname || ""} ${fee.beneficiary.lastname || ""}`.trim()
                : "";

            const agencyInfo = `Agence ${fee.agency && fee.agency != "Toutes" ? fee.agency : "non précisée"}`;
            const domainInfo = fee.domain ? fee.domain : "Domaine non précisé";

            const beneficiaryContent = [beneficiaryName.toUpperCase(), agencyInfo, domainInfo]
                .filter((info) => info.length > 0)
                .join("\n");

            const feesRow = {
                id: fee._id,
                label: fee.label,
                beneficiary: beneficiaryContent || "",
                project: fee.project || "Non précisé"
            };

            let monthExpenses = Object.fromEntries(
                Object.entries(fee.months).map(([key, value]) => {
                    if (typeof value === "object" && value !== null) {
                        const obj = value as { label: string; value: number };
                        return [obj.label, `\n${formattedPrice(obj.value)} €\n\u000A`];
                    }
                    return [key, `\n${value} €\n`];
                })
            );

            return { ...feesRow, ...monthExpenses };
        });
    
        totalByMonth = monthList.reduce((acc: any, item: any) => {
            acc[item.field] = feesData.reduce((sum, data) => {
                if (
                    ((data.beneficiary.firstname + " " + data.beneficiary.lastname).toLowerCase().includes(collabRegex || "")) &&
                    (
                        (data.project && data.project.toLowerCase().includes(projectRegex || "")) ||
                        (!projectRegex && !data.project)
                    ) && 
                    (
                        (!domainRegex && !data.domain) || 
                        (data.domain && data.domain.toLowerCase().includes(domainRegex || ""))
                    )
                ){
                    const month: any = Object.values(data.months).find(
                        (month: any) => month.label === item.field
                    );
                    return sum + (month ? (month as { label: string; value: number }).value : 0);
                }
                else{
                    return sum;
                }
            }, 0);
            return acc;
        }, {});

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );

        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            beneficiary: " \nTOTAL\n\u000A",
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `${formattedPrice(value as number)} €`])
            )
        } : null;

        setRows(feesData.length > 0 && totalRow ? [...feesRows, totalRow] : [...feesRows]);

        dispatch(setTotalByMonth("Frais de fonctionnement", totalByMonth));
    }, [feesData, projectRegex, domainRegex, collabRegex])

   

    const handleClose = () => {
        setActionType("close");
        setShowConfirmDialog(true)
    };
    const confirmClose = () => {
        setShowConfirmDialog(false);
        setActionType(null);
        setOpenModal(false);
    }

    const cancelClose = () => {
        setShowConfirmDialog(false);
        setActionType(null);
    };

    const handleOpenAddFeeModal = () => {
        setEdit(false);
        setSelectedFee(null);
        setOpenModal(true);
    };

    const handleOpenEditFeeModal = (fee: any) => {
        setEdit(true);
        setSelectedFee(fee);
        setOpenModal(true);
    };

    const handleDeleteConfirmation = () => {
        if (selectedFees.length === 0) {
            dispatch(showSnackbar("Aucun frais sélectionné pour suppression.", "error"));
            return;
        }

        const message =
            selectedFees.length === 1
                ? "Êtes-vous sûr de vouloir supprimer ce frais ?"
                : `Êtes-vous sûr de vouloir supprimer ces ${selectedFees.length} frais ?`;

        setConfirmationMessage(message);
        setActionType("delete");
        setShowConfirmDialog(true);
    };

    const confirmDelete = async () => {
        setShowConfirmDialog(false);

        try {
            const response = await axios.delete(`${urlAPI}/fee/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { selectedId: selectedFees },
            });

            if (response.data.message === "successfully") {
                dispatch(showSnackbar("Frais supprimé avec succès.", "success"));
                setFeesData((prev) => prev.filter((fee) => !selectedFees.includes(fee._id)));
                setSelectedFees([]);

            }
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);
            dispatch(showSnackbar("Erreur lors de la suppression des frais.", "error"));
        }
    };

    const handleDuplication = async () => {
        if (selectedFees.length === 0) {
            dispatch(showSnackbar("Aucun frais sélectionné pour une duplication.", "error"));
            return;
        }
        if (selectedFees.length > 1) {
            dispatch(showSnackbar("Vous ne pouvez pas dupliquer plus d'un frais en même temps.", "error"));
            return;
        }

        try {
            const response = await axios.post(`${urlAPI}/fee/duplicate/${selectedFees[0]}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.message === "successfully") {
                dispatch(showSnackbar("Frais duppliqué avec succès.", "success"));
                const newFee = response.data.data;
                setFeesData((prevFees) => [...prevFees, newFee]);
                setSelectedFees([]);
            }
        } catch (error) {
            console.error("Erreur lors de la duplication :", error);
            dispatch(showSnackbar("Erreur lors de la duplication d'un frais.", "error"));
        }
    };

    const handleSubmit = edit ? handleUpdateFee : addFee;

    const filters = [
        {id : "projectRegex", value : projectRegex, changeFunc : setProjectRegex, label : "Tri par projet"},
        {id : "collabRegex", value : collabRegex, changeFunc : setCollabRegex, label : "Tri par collaborateur"},
        {id : "domainRegex", value : domainRegex, changeFunc : setDomainRegex, label : "Tri par domaine"},
    ]

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Frais de fonctionnement</Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", width: "45%", justifyContent: "space-between", alignItems: "center", flexWrap : "wrap"}}>
                    <Select
                        labelId="agency"
                        id="agency"
                        value={agency}
                        label="Agence"
                        onChange={(event) => { setAgency(event.target.value) }}
                        sx={{ width: "45%", height: "50px" }}
                    >
                        {agencies.map((opt, index) => (
                            <MenuItem key={index} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {
                        filters.map((filter) => {
                            return (
                                <TextField
                                    margin="normal"
                                    type="text"
                                    id={filter.id}
                                    label={filter.label}
                                    name={filter.id}
                                    autoComplete={filter.id}
                                    value={filter.value}
                                    onChange={(e) => filter.changeFunc(e.target.value.toLowerCase())}
                                    sx={{width: "45%"}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                        })
                    }
                </Box>
                <Box sx={{ display: "flex", gap: 2, mt: 3, mb: 2 }}>
                    {selectedFees.length > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={handleDeleteConfirmation}>- Supprimer</Button>
                    )}
                    {selectedFees.length === 1 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer un frais</Button>
                    )}

                    <Button
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleOpenAddFeeModal}
                    >
                        + Ajouter un frais
                    </Button>
                </Box>
            </Box>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                checkboxSelection
                rowSelectionModel={selectedFees}
                onRowSelectionModelChange={(selectionModel) => {
                    setSelectedFees(selectionModel);
                }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                onCellClick={(params) => {
                    const allowedFields = ["label", "project", "beneficiary", ...monthList.map((m) => m.field)];
                    if (allowedFields.includes(params.field)) {
                        const rowId = params.row.id;
                        if (rowId) {
                            const rowData = feesData.find((fee) => fee._id === rowId);

                            if (rowData) {

                                handleOpenEditFeeModal(rowData);

                            } else {
                                console.error("Aucune donnée trouvée pour cet ID.");
                            }
                        }
                    }
                }}
                tableName="Fees"
            />

            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>
                        {edit ? "Modifier un frais" : "+ Ajouter un frais"}
                    </DialogTitle>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateFee
                    handleSubmit={handleSubmit}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                    initialValue={selectedFee || {}}
                    isEdit={edit}
                />
            </Dialog>

            <AlerteDialog
                open={showConfirmDialog}
                handleClose={cancelClose}
                deleteProject={actionType === "delete" ? confirmDelete : confirmClose}
                title={actionType === "delete" ? "Confirmer la suppression" : "Confirmer l'abandon"}
                contentText={
                    actionType === "delete"
                        ? confirmationMessage
                        : "Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                }
                color="error"
            />

        </>
    );
};

export default Fees;
