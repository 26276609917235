import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import SchoolIcon from "@mui/icons-material/School";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import { ISalaryForecast } from "../../../interface/salariesForecast";
import { ISalarialMatrice } from "../../../interface/salaryMatrice";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { formattedPrice } from "../../function/formattedPrice";

const SalariesData = ({
    salariesData,
    salarialMatrice,
    forecastDates,
    selectedSalaries,
    setSelectedSalaries,
    setEditId,
    setOpenModal,
    collabRegex,
    contract
}: {
    salariesData: ISalaryForecast[],
    salarialMatrice?: ISalarialMatrice,
    forecastDates: string,
    selectedSalaries: string[],
    setSelectedSalaries: Function,
    setEditId: Function,
    setOpenModal: Function,
    collabRegex : string,
    contract : string | null,
}) => {
    let dispatch = useDispatch();

    const [rows, setRows] = useState<GridRowsProp>([]);
    
    const monthList = getForecastMonthList(forecastDates);

    const columnsToDisplay = {
        infos: [
            { name: "Collaborateur", field: "collab" },
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 180,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: column.field === "collab" ? "sticky-column" : "",
            cellClassName: column.field === "collab" ? "sticky-column" : "",
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "20px" }}>
                    <div>
                        {params.value?.split('\n').map((line: any, index: any) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                    {(params.row.contract === "Alternant" || params.row.contract === "Stagiaire") && (<SchoolIcon />)}
                </div>
            ),
        }
    })

    const formatCellValue = (value: string) => {
        if (!value) return null;

        return value.split('\n').map((line, index) => (
            <span key={index}>
                {line.includes('--') ? <b>{line}</b> : line}
                <br />
            </span>
        ));
    };

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => ({
        field: column.field,
        headerName: column.name,
        minWidth: 250,
        headerClassName: "custom-header",
        renderCell: (params) => (
            <div style={{ whiteSpace: "normal", display: "flex", columnGap: "20px" }}>
                {formatCellValue(params.value)}
            </div>
        ),
    }));

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const totalByMonth = monthList.reduce((acc: any, item: any) => {
        acc[item.field] = 0;
        return acc;
    }, {});

    useEffect(() => {
        const salariesRows: GridRowsProp = salariesData
        .filter((salary) => contract === "All" || salary.contract === contract)
        .filter((salary) => {
            return (
                (salary.collab && (salary.collab.firstname + " " + salary.collab.lastname).toLowerCase().includes(collabRegex || "")) ||
                (salary.fictifName && salary.fictifName.toLowerCase().includes(collabRegex || ""))
            )
        })
        .map((salary: ISalaryForecast) => {
            const salaryRow: any = {
                id: salary._id,
                collab: `${(salary.type === "Réel" ? salary.collab.firstname + " " + salary.collab.lastname : salary.fictifName).toUpperCase()}`
                    + `\n\nAgence ${salary.agency}
                  \n${salary.contract}`,
                type: salary.type,
                contract: salary.contract,
            };
    
            const monthExpenses = Object.fromEntries(
                Object.entries(salary.months).map(([key, value]) => {
                    const brut = value.brutSalary * value.presenceTime / 100;
                    const bonus = value.bonus;
                    const patronal = salary.contract === "Stagiaire" ? salarialMatrice?.internPatronalContribution : (
                        salary.contract === "Alternant" ? salarialMatrice?.altPatronalContribution : salarialMatrice?.salaryPatronalContribution
                    )
                    const salarial = salary.contract === "Stagiaire" ? salarialMatrice?.internSalarialContribution : (
                        salary.contract === "Alternant" ? salarialMatrice?.altSalarialContribution : salarialMatrice?.salarySalarialContribution
                    )
                    const cotisPatron = (brut + bonus) * (patronal || 0) / 100;
                    const cotisSalary = (brut + bonus) * (salarial || 0) / 100
                    const TRAmount = value.openDays * (salarialMatrice?.amountTR || 0);
                    const TRPatron = (salarialMatrice?.patronalTR || 0) / 100 * TRAmount;
                    const TRSalary = (100 - (salarialMatrice?.patronalTR || 0)) / 100 * TRAmount;
                    const abundance = value.saving * (salarialMatrice?.abundance || 0) / 100;
                    const savingPatron = abundance * (salarialMatrice?.abundanceContribution || 0) / 100;
                    const managementFee = (salarialMatrice?.managementFee || 0) / 12;
    
                    totalByMonth[value.label] += brut + bonus + value.fees + cotisPatron + TRPatron + abundance + savingPatron + managementFee;
    
                    return [
                        value.label,
                        `Nb JO : ${value.openDays}
                        Salaire Brut : ${formattedPrice(brut)} €
                        Primes / Variables: ${formattedPrice(bonus)} €
                        Frais divers : ${formattedPrice(value.fees)} €
                        -- COTISATIONS --
                        * Cotisations Patronales : ${formattedPrice(cotisPatron)} €
                        * Cotisations Salariales : ${formattedPrice(cotisSalary)} €
                        * Salaire net : ${formattedPrice((brut - cotisSalary))} €
                        -- TICKETS RESTO -- 
                        * Montant : ${formattedPrice(TRAmount)} €
                        * Part Patronale : ${formattedPrice(TRPatron)} €
                        * Part Salariale : ${formattedPrice(TRSalary)} €
                        -- EPARGNE --
                        * Abondement : ${formattedPrice(abundance)} €
                        * Cotisations Patronales : ${formattedPrice(savingPatron)} €
                        * Frais de gestion : ${formattedPrice(managementFee)} €
                        -- TOTAL DEPENSES --
                        ${formattedPrice((brut + bonus + value.fees + cotisPatron + TRPatron + abundance + savingPatron + managementFee))} €`
                    ]
                })
    
            );
    
            return { ...salaryRow, ...monthExpenses };
        });

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );
    
        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total", // Une ID unique pour cette ligne
            collab: `\nTOTAL\n`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `\n${formattedPrice((value as number))} €\n`])
            )
        } : null;
    
        setRows(salariesData.length > 0 && totalRow ? [...salariesRows, totalRow] : [...salariesRows]);
    
        dispatch(setTotalByMonth("Salariés", totalByMonth));
    }, [salariesData, collabRegex, contract])    

    return (
        <>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => {
                    if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); }
                }}

                checkboxSelection
                rowSelectionModel={selectedSalaries}
                onRowSelectionModelChange={(newSelection) => {
                    setSelectedSalaries(newSelection);
                }}
                isRowSelectable={(params) => {
                    return (params.row.id !== 'total' && params.row.type === "Fictif");
                }}
                stickyColumn="collab"
                tableName="Salaries"
            />
        </>
    )
}

export default SalariesData;