import { Alert, Autocomplete, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IFees } from "../../../interface/fees";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const InvestInfosFields = ({
    fee,
    formik,
}: {
    fee?: IFees;
    formik: any;
}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.user.token);

    const [message, setMessage] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isRealProject, setIsRealProject] = useState<boolean>();
    const [projects, setProjects] = useState<any>([]);

    const selectInputs = [
        {
            id: "agency",
            options: ["Nord", "Sud"],
            defaultValue: fee?.agency || "",
            label: "Agence",
            touched: formik.touched.agency,
            errors: formik.errors.agency,
            required: true,
        },
        {
            id: "domain",
            options: ["Mobilier", "PC", "Serveurs ", "Infra", "Travaux - CDC", "Autres"],
            defaultValue: fee?.domain || "",
            label: "Domaine",
            touched: formik.touched.domain,
            errors: formik.errors.domain,
            required: true,
        },
    ];

    useEffect(() => {
        setIsRealProject((formik.values.project).includes("PJ") ? true : false);
      }, [formik.values.project])

    const fetchProjects = async () => {
        try {
          const response = await axios.get(`${urlAPI}/projects/dolibarr/getAllProjects`, {
            headers: { Authorization: `Bearer ${token}` },
          });
    
          setProjects(response.data);
        } catch (error: any) {
          console.log(error.response.data.error);
        }
      };
    
    useEffect(() => {
    if (isRealProject) {
        fetchProjects();
    }
    }, [isRealProject]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "30%",
            }}
        >
            <>
                <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealProject ? "bold" : ""}}>Projet Fictif</Typography>
                <Switch
                    checked={isRealProject}
                    onChange={() => setIsRealProject(!isRealProject)}
                />
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealProject ? "bold" : ""}}>Projet Réel</Typography>
                </Box>


                {isRealProject ? (
                <Autocomplete
                    options={projects}
                    getOptionLabel={(option: any) => `${option.ref} - ${option.title}`} // Affiche ref + title
                    isOptionEqualToValue={(option: any, value: any) => {
                    if (!value || typeof value !== "string") return false; // Vérifie que value est bien une string
                    return option.ref === value.split(" - ")[0]; // Compare la ref
                    }}
                    
                    value={projects.find((p: any) => `${p.ref} - ${p.title}` === formik.values.project) || null} // Recherche via concat
                    onChange={(event, value: any) => {
                    formik.setFieldValue("project", value ? `${value.ref} - ${value.title}` : ""); // Stocke ref - title
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        margin="normal"
                        label="Projet associé"
                        error={formik.touched.project && Boolean(formik.errors.project)}
                        helperText={formik.touched.project && formik.errors.project}
                    />
                    )}
                />
                ) : (
                <TextField
                    margin="normal"
                    required
                    type="text"
                    id="project"
                    label="Projet associé"
                    name="project"
                    autoComplete="project"
                    value={formik.values.project}
                    onChange={formik.handleChange}
                    error={formik.touched.project && Boolean(formik.errors.project)}
                    helperText={formik.touched.project && formik.errors.project}
                    autoFocus
                />
                )}
            </>
            <TextField
                margin="normal"
                required
                type="text"
                id="label"
                label="Libellé du frais"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                autoFocus
            />
            {selectInputs.map((select) => {
                return (
                    <Autocomplete
                        key={select.id}
                        id={select.id}
                        options={select.options}
                        value={formik.values[select.id] || null}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, value) => formik.setFieldValue(select.id, value || "")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={select.id}
                                margin="normal"
                                label={select.label}
                                error={select.touched && Boolean(select.errors)}
                                helperText={select.touched && select.errors}
                            />
                        )}
                    />
                );
            })}



            {/* Error and Success Messages */}
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            
        </Box>
    );
};

export default InvestInfosFields;
