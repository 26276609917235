import { Box, Button, Dialog, DialogTitle, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import DGFIPData from "./DGFIPData";
import AddUpdateDGFIP from "../AddUpdate/AddUpdateDGFIP";
import { addUpdateDGFIP } from "../../../tools/forecast/addUpdateDGFIP";
import { duplicateDGFIP } from "../../../tools/forecast/duplicateDGFIP";

const DGFIP = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let dispatch = useDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [selectedDGFIP, setSelectedDGFIP] = useState([]);
    const [editId, setEditId] = useState<string>("");
    const [projectRegex, setProjectRegex] = useState<string>("");

    const params = useParams();

    const monthList = getForecastMonthList(forecastDates);

    const confirmDelete = async () => {
        try {
            selectedDGFIP.forEach(async (dgfipId) => {
                const response = await axios.delete(
                    `${urlAPI}/forecast/dgfip/${dgfipId}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                );
            })
            setShowDeleteDialog(false);
            setSelectedDGFIP([])
            dispatch(showSnackbar("Frais DGFIP supprimé(s) avec succès.", "success"));
        } catch (error: any) {
            
            if (error.response) {
                console.error("Erreur API :", error.response.data);
                console.error("Statut :", error.response.status); 
            } else if (error.request) {
                console.error("Erreur de requête :", error.request);
            } else {
                console.error("Erreur :", error.message);
            }
            dispatch(showSnackbar("Erreur lors de la suppression.", "error"));
        }
    }

    const handleAddUpdate = () => {
        setEditId("");
        setOpenModal(true);
    }

    const handleDuplication = () => {
        duplicateDGFIP(selectedDGFIP[0], token, setOpenModal, dispatch);
        setSelectedDGFIP([]);
    }

    return (
        <>
            <Typography variant="h3" sx={{textAlign: "center", backgroundColor: "#FFD28E"}}>DGFIP</Typography>
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                <TextField
                    margin="normal"
                    type="text"
                    id="projectRegex"
                    label="Tri par projet"
                    name="projectRegex"
                    autoComplete="projectRegex"
                    value={projectRegex}
                    onChange={(e) => setProjectRegex(e.target.value.toLowerCase())}
                    sx={{width: 300}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{display : "flex", justifyContent: "right", columnGap: "50px"}}>
                    {selectedDGFIP.length > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={() => setShowDeleteDialog(true)}>- Supprimer</Button>
                    )}
                    {selectedDGFIP.length == 1 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer le frais</Button>
                    )}
                    <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleAddUpdate}>
                        + Ajouter un frais
                    </Button>
                </Box>
            </Box>
            <DGFIPData
                forecastVersion={forecastVersion}
                forecastDates={forecastDates}
                selectedDGFIP={selectedDGFIP}
                setSelectedDGFIP={setSelectedDGFIP}
                openModal={openModal}
                showDeleteDialog={showDeleteDialog}
                setEditId={setEditId}
                setOpenModal={setOpenModal}
                projectRegex={projectRegex}
            />
            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
                onKeyDown={(event) => {

                }}
            >
                <Box sx={{display : "flex", justifyContent: "space-between"}}>
                    <DialogTitle>{editId ? "Modifier le frais" : "+ Ajouter un frais"}</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateDGFIP
                    dgfipId={editId} 
                    handleSubmit={addUpdateDGFIP} 
                    forecastId={params.id} 
                    version={forecastVersion} 
                    setOpenModal={setOpenModal} 
                    monthList={monthList} 
                    setShowConfirmDialog={setShowConfirmDialog}
                />
            </Dialog>
            <AlerteDialog
                open={showConfirmDialog}
                handleClose={() => setShowConfirmDialog(false)}
                deleteProject={() => {setShowConfirmDialog(false); setOpenModal(false); setEditId("")}}
                title="Confirmer l'abandon"
                contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                color="error" 
            />
            <AlerteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                deleteProject={confirmDelete}
                title="Confirmer la suppression"
                contentText={`Vous êtes sur le point de supprimer ${selectedDGFIP.length} lignes. Êtes-vous sûr de vouloir supprimer les epargnes sélectionnées de façon définitive ?`}
                color="error" 
            />
        </>
    )
}

export default DGFIP;