import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useCallback, useEffect, useMemo, useState } from "react";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { ITurnover, ITurnoverFormValues } from "../../../interface/turnover";
import TurnoverInfosFields from "./TurnoverInfosFields";
import TurnoverMonthFields from "./TurnoverMonthFields";
import getBusinessDaysForMonth from "../../../tools/getBusinessDays";
import axios from "axios";
import { urlAPI } from "../../../config/config";

const validationSchema = yup.object({
  agency: yup.string().trim(),
  collab: yup.string(),
  project: yup.string(),
  client: yup.string().required("Le client est obligatoire"),
  type: yup.string().required("Le type de prestation est obligatoire"),
});

const AddUpdateTurnover = ({
  turnoverId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  monthlyAmount
}: {
  turnoverId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[],
  monthlyAmount: number,
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [turnover, setTurnover] = useState<ITurnover>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [tjm, setTjm] = useState<number>(0);

  const getTurnover = async () => {
    if (!token || !turnoverId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/turnover/${turnoverId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTurnover(syncResponse.data.message);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getTurnover();
  }, []);

  const [error, setError] = useState("");

  const initialValues = useMemo(() => ({
    agency: turnover ? turnover.agency : "Toutes",
    collab: turnover && turnover.collab ? turnover.collab._id : "",
    fictifCollab: turnover && turnover.fictifCollab ? turnover.fictifCollab : "",
    project: turnover ? turnover.project : "",
    label: turnover ? turnover.label : "",
    client: turnover ? turnover.client._id : "",
    type: turnover ? turnover.type : "",
    monthlyAmount: turnover ? turnover.monthlyAmount : 0,
    months: turnover ? turnover.months
      : monthList.map((item: any) => ({
          label: item.field,
          openDays: -1,
          tjm: 0,
          amount: 0,
        })),
  }), [turnover, monthList]);
  
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: ITurnoverFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, turnoverId);
    },
    enableReinitialize: true,
  });
  
  useEffect(() => {
    const updatedMonths = formik.values.months.map((month) => {
      // Vérifie si c'est une nouvelle ligne (jamais modifiée)
      const isNewEntry = month.openDays === undefined || month.openDays === null || month.openDays === -1;

  
      // Si la ligne est nouvelle ou jamais modifiée ET que openDays vaut 0, on initialise
      if (isNewEntry) {
        const [monthName, yearStr] = month.label.split(/(\d+)/).filter(Boolean);
        const year = Number(yearStr);
  
        return {
          ...month,
          openDays: getBusinessDaysForMonth(year, monthName.trim()),
        };
      }
  
      // Si l'utilisateur a modifié (touché), on ne change rien (y compris s'il a mis 0)
      return month;
    });
  
    // Mise à jour uniquement si les valeurs sont réellement différentes
    if (JSON.stringify(updatedMonths) !== JSON.stringify(formik.values.months)) {
      formik.setFieldValue("months", updatedMonths, false); // `false` évite de marquer tout le formik comme touché
    }
  }, [formik.values.months]);
  



const handleClose = useCallback(() => {
  setShowConfirmDialog(true);
}, []);

const confirmClose = useCallback(() => {
  setShowConfirmDialog(false);
  setOpenModal(false);
}, [setOpenModal]);

const cancelClose = useCallback(() => {
  setShowConfirmDialog(false);
}, []);


  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <TurnoverInfosFields turnover={turnover} formik={formik} setTjm={setTjm} />
        <TurnoverMonthFields formik={formik} monthList={monthList} tjm={tjm} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
      <AlerteDialog
        open={showConfirmDialog}
        handleClose={cancelClose}
        deleteProject={confirmClose}
        title="Confirmer l'abandon"
        contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
        color="error" />
    </Box>
  )
}

export default AddUpdateTurnover;