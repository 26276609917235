import { Box } from "@mui/system";
import { Alert, Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { colorOrange } from "../../../config/color";
import { IClient } from "../../../interface/client";
import { getAll } from "../../../fetchs/get";
import { sessionEnd } from "../../../redux/actions";
import { ICompany } from "../../../interface/company";

const CompanyLabelField = ({
  company,
  formik,
}: {
  company?: ICompany,
  formik: any
}) => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const token = useSelector((state: RootState) => state.user.token);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isRealProject, setIsRealProject] = useState<boolean>();
  const [isRealSupplier, setIsRealSupplier] = useState<boolean>();
  const [projects, setProjects] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<IClient[]>([]);

  const getAllSuppliers = useCallback(() => {
    if (token) {
      getAll(token, "/suppliers/", setSuppliers, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  const supplierActeam = suppliers.map((client: IClient) => ({
    _id: client._id,
    name: client.name,
  })) as unknown as readonly string[];
  
  useEffect(() => {
    getAllSuppliers();
  }, [getAllSuppliers]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${urlAPI}/projects/dolibarr/getAllProjects`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProjects(response.data);
    } catch (error: any) {
      console.log(error.response.data.error);
    }
  };

  useEffect(() => {
    setIsRealProject((formik.values.project).includes("PJ") ? true : false);
  }, [formik.values.project])

  useEffect(() => {
    setIsRealSupplier(formik.values.supplier ? true : false);
  }, [formik.values.supplier])

  const handleFetch = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/suppliers/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await getAllSuppliers();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des fournisseurs.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isRealProject) {
      fetchProjects();
    }
  }, [isRealProject]);

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
            <TextField
              margin="normal"
              required
              type="text"
              id="customLabel"
              label="Libellé du frais d'entreprise"
              name="label"
              autoComplete="label"
              value={formik.values.label}
              onChange={formik.handleChange}
              autoFocus
            />
            <>
              <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealProject ? "bold" : ""}}>Projet Fictif</Typography>
                <Switch
                  checked={isRealProject}
                  onChange={() => setIsRealProject(!isRealProject)}
                />
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealProject ? "bold" : ""}}>Projet Réel</Typography>
              </Box>


              {isRealProject ? (
                <Autocomplete
                  options={projects}
                  getOptionLabel={(option: any) => `${option.ref} - ${option.title}`} // Affiche ref + title
                  isOptionEqualToValue={(option: any, value: any) => {
                    if (!value || typeof value !== "string") return false; // Vérifie que value est bien une string
                    return option.ref === value.split(" - ")[0]; // Compare la ref
                  }}
                  
                  value={projects.find((p: any) => `${p.ref} - ${p.title}` === formik.values.project) || null} // Recherche via concat
                  onChange={(event, value: any) => {
                    formik.setFieldValue("project", value ? `${value.ref} - ${value.title}` : ""); // Stocke ref - title
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="normal"
                      label="Projet associé"
                      error={formik.touched.project && Boolean(formik.errors.project)}
                      helperText={formik.touched.project && formik.errors.project}
                    />
                  )}
                />
              ) : (
                <TextField
                  margin="normal"
                  required
                  type="text"
                  id="project"
                  label="Projet associé"
                  name="project"
                  autoComplete="project"
                  value={formik.values.project}
                  onChange={formik.handleChange}
                  error={formik.touched.project && Boolean(formik.errors.project)}
                  helperText={formik.touched.project && formik.errors.project}
                  autoFocus
                />
              )}
            </>
            <>
              <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealSupplier ? "bold" : ""}}>Fournisseur Fictif</Typography>
                <Switch
                  checked={isRealSupplier}
                  onChange={() => setIsRealSupplier(!isRealSupplier)}
                />
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealSupplier ? "bold" : ""}}>Fournisseur Réel</Typography>
              </Box>


              {isRealSupplier ? (
                <Autocomplete
                  options={supplierActeam}
                  getOptionLabel={(option: any) => `${option.name}`}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option._id === value._id; 
                  }}
                  value={supplierActeam.find((supplier: any) => supplier._id === formik.values.supplier)}
                  onChange={(event, value: any) => {
                    formik.setFieldValue("supplier", value ? `${value._id}` : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Fournisseur Réel"
                      error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                      helperText={formik.touched.supplier && formik.errors.supplier}
                    />
                  )}
                />
              ) : (
                <TextField
                  margin="normal"
                  type="text"
                  id="fictifSupplier"
                  label="Fournisseur fictif"
                  name="fictifSupplier"
                  autoComplete="fictifSupplier"
                  value={formik.values.fictifSupplier}
                  onChange={formik.handleChange}
                  error={formik.touched.fictifSupplier && Boolean(formik.errors.fictifSupplier)}
                  helperText={formik.touched.fictifSupplier && formik.errors.fictifSupplier}
                  autoFocus
                />
              )}
            </>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            {loading ? (
              <CircularProgress sx={{ margin: "16px auto" }} />
            ) : (
                <>
                    <Button
                        onClick={handleFetch}
                        fullWidth
                        sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
                    >
                        {"Mettre à jour la liste des fournisseurs"}
                    </Button>
                </>
            )}
        </Box>
    </>
  )
}

export default CompanyLabelField;

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}