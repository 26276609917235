import { Box, Button, CircularProgress, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useParams } from "react-router";
import SalariesData from "./SalariesData";
import { generateForecastSalaries } from "../../../tools/forecast/generateForecastSalaries";
import { ISalaryForecast } from "../../../interface/salariesForecast";
import getForecastSalariesData from "../../../tools/forecast/getForecastSalariesData";
import { duplicateSalary } from "../../../tools/forecast/duplicateSalary";
import SalariesModals from "./SalariesModals";
import { ISalarialMatrice } from "../../../interface/salaryMatrice";
import getSalarialMatrice from "../../../tools/forecast/getSalarialMatrice";
import SearchIcon from "@mui/icons-material/Search";

const Salaries = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let dispatch = useDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openMatriceModal, setOpenMatriceModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [selectedSalaries, setSelectedSalaries] = useState([]);
    const [editId, setEditId] = useState<string>("");
    const [salariesData, setSalariesData] = useState<ISalaryForecast[]>([]);
    const [agency, setAgency] = useState<string | null>("All");
    const agencies = [
        { label: "Toutes les agences", value: "All" },
        { label: "Agence Nord", value: "Nord" },
        { label: "Agence Sud", value: "Sud" }
    ];
    const [contract, setContract] = useState<string | null>("All");
    const contracts = [
        { label: "Tous les contrats", value: "All" },
        { label: "CDI", value: "CDI" },
        { label: "CDD", value: "CDD" },
        { label: "Alternant", value: "Alternant" },
        { label: "Stagiaire", value: "Stagiaire" },
    ];
    const [collabRegex, setCollabRegex] = useState<string>("");
    const [salarialMatrice, setSalarialMatrice] = useState<ISalarialMatrice>();

    const params = useParams();

    useEffect(()=> {
        getForecastSalariesData(params.id, forecastVersion, agency, setSalariesData, token, dispatch);
        getSalarialMatrice(params.id, forecastVersion, setSalarialMatrice, token)
    }, [forecastVersion, agency, openModal, openMatriceModal, showDeleteDialog, selectedSalaries])

    const confirmDelete = async () => {
        try {
            selectedSalaries.forEach(async (salaryId) => {
                const response = await axios.delete(
                    `${urlAPI}/forecast/salaries/${salaryId}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                );
            })
            setShowDeleteDialog(false);
            setSelectedSalaries([])
            dispatch(showSnackbar("Salarié(s) fictif(s) supprimé(s) avec succès.", "success"));
        } catch (error: any) {
            
            if (error.response) {
                console.error("Erreur API :", error.response.data);
                console.error("Statut :", error.response.status); 
            } else if (error.request) {
                console.error("Erreur de requête :", error.request);
            } else {
                console.error("Erreur :", error.message);
            }
            dispatch(showSnackbar("Erreur lors de la suppression.", "error"));
        }
    }

    const handleAddUpdate = () => {
        setEditId("");
        setOpenModal(true);
    }

    const handleDuplication = () => {
        duplicateSalary(selectedSalaries[0], token, setOpenModal, dispatch);
        setSelectedSalaries([]);
    }

    const handleGenerate = async () => {
        await handleFetch();
        generateForecastSalaries(forecastDates, setSalariesData, dispatch, params.id, forecastVersion, token);
        setLoading(false);
    }

    const handleFetch = async () => {
        setLoading(true);
        try {
          const syncResponse = await axios.get(`${urlAPI}/salary/dolibarr`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (error: any) {
          const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
        }
    };

    return (
        <>
            <Typography variant="h3" sx={{textAlign: "center", backgroundColor: "#FFD28E"}}>Salariés</Typography>
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                <Box sx={{display: "flex", columnGap: "20px", justifyContent: "left", alignItems: "center"}}>
                    {loading ? (
                        <CircularProgress sx={{ margin: "16px auto" }} />
                    ) : (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 1, width: "210px" }} onClick={handleGenerate}>
                            Générer liste salariés
                        </Button>
                    )}
                    <Button type="button" variant="contained" sx={{ mt: 3, mb: 1, width: "290px" }} onClick={()=>{setOpenMatriceModal(true)}}>
                        Mettre à jour matrice salariale
                    </Button>
                </Box>
                <Box sx={{display : "flex", justifyContent: "right", columnGap: "20px", width: "600px"}}>
                    {selectedSalaries.length > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={() => setShowDeleteDialog(true)}>- Supprimer</Button>
                    )}
                    
                    {selectedSalaries.length == 1 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer le salarié</Button>
                    )}
                    <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleAddUpdate}>
                        + Ajouter un salarié fictif
                    </Button>
                </Box>
            </Box>
            <Box sx={{display : "flex", justifyContent: "left", alignItems: "center", columnGap: "20px", width: "1000px", marginBottom: "20px"}}>
                <Select
                    labelId="agency"
                    id="agency"
                    value={agency}
                    label="Agence"
                    onChange={(event) => { setAgency(event.target.value) }}
                    sx={{ width: "200px", height: "50px" }}
                >
                    {agencies.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    labelId="contract"
                    id="contract"
                    value={contract}
                    label="Contrat"
                    onChange={(event) => { setContract(event.target.value) }}
                    sx={{ width: "200px", height: "50px" }}
                >
                    {contracts.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    margin="normal"
                    type="text"
                    id="supplierRegex"
                    label="Tri par salarié"
                    name="collabRegex"
                    autoComplete="collabRegex"
                    value={collabRegex}
                    onChange={(e) => setCollabRegex(e.target.value.toLowerCase())}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <SalariesData
                salariesData={salariesData}
                salarialMatrice={salarialMatrice}
                forecastDates={forecastDates}
                selectedSalaries={selectedSalaries}
                setSelectedSalaries={setSelectedSalaries}
                setEditId={setEditId}
                setOpenModal={setOpenModal}
                collabRegex={collabRegex}
                contract={contract}
            />
            <SalariesModals 
                openModal={openModal}
                setOpenModal={setOpenModal}
                showConfirmDialog={showConfirmDialog}
                setShowConfirmDialog={setShowConfirmDialog}
                openMatriceModal={openMatriceModal}
                setOpenMatriceModal={setOpenMatriceModal}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                editId={editId}
                setEditId={setEditId}
                forecastVersion={forecastVersion}
                forecastDates={forecastDates}
                confirmDelete={confirmDelete}
                selectedSalaries={selectedSalaries}
                salarialMatrice={salarialMatrice}
            />
        </>
    )
}

export default Salaries;