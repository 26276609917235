import { Alert, Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colorOrange } from "../../../config/color";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { ITurnover } from "../../../interface/turnover";
import { sessionEnd } from "../../../redux/actions";
import { IClient } from "../../../interface/client";
import { getAll } from "../../../fetchs/get";
import TurnoverCollabField from "./TurnoverCollabField";

const TurnoverInfosFields = ({
  turnover,
  formik,
  setTjm
}: {
  turnover?: ITurnover,
  formik: any,
  setTjm: Function
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [salaries, setSalaries] = useState<any>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [projects, setProjects] = useState<any>([]);
  const [isRealProject, setIsRealProject] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(false);

  const getAllClients = useCallback(() => {
    if (token) {
      getAll(token, "/clients/", setClients, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  useEffect(() => {
    getAllClients();
  }, [getAllClients]);

  useEffect(() => {
    setIsRealProject((formik.values.project).includes("PJ") ? true : false);
  }, [formik.values.project])


  const fetchSalaries = async () => {
    try {
      const response = await axios.get(`${urlAPI}/salary`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSalaries(response.data.data);
    } catch (error: any) {
      setError("Erreur lors de la récupération des salariés");
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    fetchSalaries();
  }, [])

  const fetchProjects = async (clientId: string) => {
    if (!clientId) return;
    setLoading(true);
    try {
      const response = await axios.get(`${urlAPI}/projects/getProjectsDolibarrByClient/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProjects(response.data);
    } catch (error: any) {
      setError("Erreur lors de la récupération des projets");
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formik.values.client && isRealProject) {

      const dolibarrId = clients.find((client: IClient) => client._id === formik.values.client)?.dolibarr_id;

      if (!dolibarrId) return;
      fetchProjects(dolibarrId);
    }
  }, [formik.values.client, isRealProject]);


  const selectInputs = [
    { id: "type", options: ["Forfait", "Hébergement", "Licence", "Régie", "TMA/TME", "TRA"], value: formik.values.type, label: "Type de prestation", touched: formik.touched.type, errors: formik.errors.type, required: true },
    { id: "agency", options: ["Toutes", "Nord", "Sud"], value: formik.values.agency, label: "Agence", touched: formik.touched.agency, errors: formik.errors.agency, required: false },
    { id: "client", options: clientActeam, value: clientActeam.find((client: any) => client._id === formik.values.client) || null, label: "Client", touched: formik.touched.client, errors: formik.errors.client, required: true },
  ]

  if (formik.values.type === "Régie") {
    selectInputs.push(
      { id: "collab", options: salaries, value: salaries.find((salary: any) => salary._id === formik.values.collab) || null, label: "Collaborateur réel", touched: "", errors: "", required: false }
    )
  }

  const handleFetch = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/salary/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await fetchSalaries();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchClient = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/clients/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await getAllClients();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des fournisseurs.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
        {
          selectInputs.map((select) => {
            if (select.id === "collab") {
              return <TurnoverCollabField formik={formik} salaries={select.options} value={select.value} setTjm={setTjm} />
            }
            else {
              return (
                <>
                  {select.id === "agency" && 
                    <TextField
                      margin="normal"
                      type="text"
                      id="label"
                      label="Libellé"
                      name="label"
                      autoComplete="label"
                      value={formik.values.label}
                      onChange={formik.handleChange}
                      error={formik.touched.label && Boolean(formik.errors.label)}
                      helperText={formik.touched.label && formik.errors.label}
                      autoFocus
                  />}
                  <Autocomplete
                    key={select.id}
                    id={select.id}
                    options={select.options}
                    getOptionLabel={(option: any) => select.id === "client" ? `${option.name || ""}`.trim() : option}
                    isOptionEqualToValue={(option: any, value: any) =>
                      select.id === "client"
                        ? option._id === value?._id
                        : option === value
                    }
                    value={select.value}
                    onChange={(event, value: any) => {
                      formik.setFieldValue(
                        select.id,
                        value ? (select.id === "client" ? value._id : value) : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={select.required}
                        name={select.id}
                        margin="normal"
                        label={select.label}
                        error={select.touched && Boolean(select.errors)}
                        helperText={select.touched && select.errors}
                      />
                    )}
                  />
                </>
              )
            }
          })
        }
        {formik.values.type && formik.values.type !== "Régie" && (
          <>
            <Box sx={{display : "flex", justifyContent: "center"}}>
              <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealProject ? "bold" : ""}}>Projet Fictif</Typography>
              <Switch
                checked={isRealProject}
                onChange={() => setIsRealProject(!isRealProject)}
                disabled={!formik.values.client}
              />
              <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealProject ? "bold" : ""}}>Projet Réel</Typography>
            </Box>


            {isRealProject ? (
              <Autocomplete
                disabled={!formik.values.client}
                options={projects}
                getOptionLabel={(option: any) => `${option.ref} - ${option.title}`} // Affiche ref + title
                isOptionEqualToValue={(option: any, value: any) => {
                  if (!value || typeof value !== "string") return false; // Vérifie que value est bien une string
                  return option.ref === value.split(" - ")[0]; // Compare la ref
                }}
                
                value={projects.find((p: any) => `${p.ref} - ${p.title}` === formik.values.project) || null} // Recherche via concat
                onChange={(event, value: any) => {
                  formik.setFieldValue("project", value ? `${value.ref} - ${value.title}` : ""); // Stocke ref - title
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    margin="normal"
                    label="Projet associé"
                    error={formik.touched.project && Boolean(formik.errors.project)}
                    helperText={formik.touched.project && formik.errors.project}
                  />
                )}
              />
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="project"
                label="Projet associé"
                name="project"
                autoComplete="project"
                value={formik.values.project}
                onChange={formik.handleChange}
                error={formik.touched.project && Boolean(formik.errors.project)}
                helperText={formik.touched.project && formik.errors.project}
                autoFocus
              />
            )}
          </>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
        {loading ? (
          <CircularProgress sx={{ margin: "16px auto" }} />
        ) : (
          <>
            <Button
              onClick={handleFetchClient}
              fullWidth
              sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
            >
              {"Mettre à jour la liste des clients"}
            </Button>
            {formik.values.type === "Régie" &&
              <Button
                onClick={handleFetch}
                fullWidth
                sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
              >
                {"Mettre à jour la liste des collaborateurs"}
              </Button>
            }
          </>
        )}
      </Box>
    </>
  )
}

export default TurnoverInfosFields;