import { Box } from "@mui/system";
import { Autocomplete, Switch, TextField, Typography} from "@mui/material";
import { IDGFIP } from "../../../interface/dgfip";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const DGFIPLabelField = ({
  dgfip,
  formik,
}: {
  dgfip?: IDGFIP,
  formik: any
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);
  
  const [isRealProject, setIsRealProject] = useState<boolean>();
  const [projects, setProjects] = useState<any>([]);
  useEffect(() => {
    setIsRealProject((formik.values.project).includes("PJ") ? true : false);
  }, [formik.values.project])

  const fetchProjects = async () => {
      try {
        const response = await axios.get(`${urlAPI}/projects/dolibarr/getAllProjects`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setProjects(response.data);
      } catch (error: any) {
        console.log(error.response.data.error);
      }
    };

  useEffect(() => {
  if (isRealProject) {
      fetchProjects();
  }
  }, [isRealProject]);

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
          <>
            <Box sx={{display : "flex", justifyContent: "center"}}>
              <Typography variant="h6" sx={{textAlign: "center", fontWeight : !isRealProject ? "bold" : ""}}>Projet Fictif</Typography>
              <Switch
                checked={isRealProject}
                onChange={() => setIsRealProject(!isRealProject)}
              />
              <Typography variant="h6" sx={{textAlign: "center", fontWeight : isRealProject ? "bold" : ""}}>Projet Réel</Typography>
            </Box>


            {isRealProject ? (
              <Autocomplete
                options={projects}
                getOptionLabel={(option: any) => `${option.ref} - ${option.title}`} // Affiche ref + title
                isOptionEqualToValue={(option: any, value: any) => {
                  if (!value || typeof value !== "string") return false; // Vérifie que value est bien une string
                  return option.ref === value.split(" - ")[0]; // Compare la ref
                }}
                
                value={projects.find((p: any) => `${p.ref} - ${p.title}` === formik.values.project) || null} // Recherche via concat
                onChange={(event, value: any) => {
                  formik.setFieldValue("project", value ? `${value.ref} - ${value.title}` : ""); // Stocke ref - title
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    margin="normal"
                    label="Projet associé"
                    error={formik.touched.project && Boolean(formik.errors.project)}
                    helperText={formik.touched.project && formik.errors.project}
                  />
                )}
              />
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="project"
                label="Projet associé"
                name="project"
                autoComplete="project"
                value={formik.values.project}
                onChange={formik.handleChange}
                error={formik.touched.project && Boolean(formik.errors.project)}
                helperText={formik.touched.project && formik.errors.project}
                autoFocus
              />
            )}
          </>
          {!dgfip ? (
            <>
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé du frais d'entreprise"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            </>
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé de l'épargne"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            )}
        </Box>
    </>
  )
}

export default DGFIPLabelField;