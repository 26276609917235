import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import AddUpdateSalaries from "../AddUpdate/AddUpdateSalaries";
import SalaryMatrice from "../AddUpdate/SalaryMatrice";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { addUpdateSalarialMatrice } from "../../../tools/forecast/addUpdateSalarialMatrice";
import { useParams } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { ISalarialMatrice } from "../../../interface/salaryMatrice";
import { addUpdateSalaries } from "../../../tools/forecast/addUpdateSalaries";


const SalariesModals = ({
    openModal,
    setOpenModal,
    showConfirmDialog,
    setShowConfirmDialog,
    openMatriceModal,
    setOpenMatriceModal,
    showDeleteDialog,
    setShowDeleteDialog,
    editId,
    setEditId,
    forecastVersion,
    forecastDates,
    confirmDelete,
    selectedSalaries,
    salarialMatrice,
}: {
    openModal: boolean,
    setOpenModal: Function,
    showConfirmDialog: boolean,
    setShowConfirmDialog: Function,
    openMatriceModal: boolean,
    setOpenMatriceModal: Function,
    showDeleteDialog: boolean,
    setShowDeleteDialog: Function,
    editId: string,
    setEditId: Function,
    forecastVersion?: number,
    forecastDates: string,
    confirmDelete: Function,
    selectedSalaries: string[],
    salarialMatrice?: ISalarialMatrice
}) => {
    const params = useParams();
    const monthList = getForecastMonthList(forecastDates);

    return (
        <>
            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>{editId ? "Modifier un salarié" : "+ Ajouter un salarié fictif"}</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateSalaries
                    salaryId={editId}
                    handleSubmit={addUpdateSalaries}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                    setShowConfirmDialog={setShowConfirmDialog}
                />
            </Dialog>
            <Dialog
                open={openMatriceModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenMatriceModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="paper"
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>Matrice Salariale</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="subtitle2" sx={{ paddingInline: "25px", color: "red" }}>
                    Attention : la modification des valeurs de la matrice entrainera un recalcul des valeurs déjà saisies dans la section "Salariés"
                </Typography>
                <SalaryMatrice
                    handleSubmit={addUpdateSalarialMatrice}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenMatriceModal}
                    setShowConfirmDialog={setShowConfirmDialog}
                    salarialMatrice={salarialMatrice}
                />
            </Dialog>
            <AlerteDialog
                open={showConfirmDialog}
                handleClose={() => setShowConfirmDialog(false)}
                deleteProject={() => { setShowConfirmDialog(false); setOpenModal(false); setOpenMatriceModal(false); setEditId("") }}
                title="Confirmer l'abandon"
                contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                color="error"
            />
            <AlerteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                deleteProject={confirmDelete}
                title="Confirmer la suppression"
                contentText={`Vous êtes sur le point de supprimer ${selectedSalaries.length} salarié(s) fictif(s). 
                            Êtes-vous sûr de vouloir supprimer les epargnes sélectionnées de façon définitive ?`}
                color="error"
            />
        </>
    )
}

export default SalariesModals;