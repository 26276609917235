import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ITNS } from "../../../interface/tns";
import getDgfipData from "../../../tools/forecast/getDgfipData";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { formattedPrice } from "../../function/formattedPrice";

const DGFIPData = ({
    forecastVersion,
    forecastDates,
    selectedDGFIP,
    setSelectedDGFIP,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal,
    projectRegex,
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    selectedDGFIP: string[],
    setSelectedDGFIP: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setEditId: Function,
    setOpenModal: Function,
    projectRegex: string
}) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [dgfipData, setDgfipData] = useState<any[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        getDgfipData(params.id, forecastVersion, setDgfipData, token, dispatch, setError);
    }, [forecastVersion, openModal, showDeleteDialog, selectedDGFIP]);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
            { name: "Projet", field: "project" },
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: "custom-header",
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {params.value?.split('\n').map((line: any, index: any) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            ),
        }
    })

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    let totalByMonth = {};

    useEffect(() => {
        const turnoverRows: GridRowsProp = dgfipData
        .filter((tns) => 
            !tns.project || tns.project.toLowerCase().includes(projectRegex || "")
        )
        .map((tns: ITNS) => {
            const turnoverRow: any = {
                id: tns._id,
                label: `\n${tns.label}\n\u000A`,
                project: tns.project || "Non précisé"
            };

            let monthExpenses = tns.months.map((month) => {
                return [month.label, `\n${formattedPrice(month.value as number)} €\n\u000A`]; 
            });

            return { ...turnoverRow, ...Object.fromEntries(monthExpenses) };
        });
    
        totalByMonth = monthList.reduce((acc: any, item: any) => {
            acc[item.field] = dgfipData.reduce((sum, tns) => {
                if(!tns.project || tns.project.toLowerCase().includes(projectRegex || "")){
                    const monthData = tns.months.find((month: any) => month.label === item.field);
                    return sum + (monthData ? monthData.value : 0);
                }
                else{
                    return sum;
                }
            }, 0);
            return acc;
        }, {});

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );
        
        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            label: `\nTOTAL\n\u000A`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `\n${formattedPrice(value as number)} €\n\u000A`])
            )
        } : null;

        setRows(dgfipData.length > 0 && totalRow ? [...turnoverRows, totalRow] : [...turnoverRows]);

        dispatch(setTotalByMonth("DGFIP", totalByMonth));   
    }, [dgfipData, projectRegex])

    return (
        <>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}

                checkboxSelection
                rowSelectionModel={selectedDGFIP}
                onRowSelectionModelChange={(selectionModel) => { setSelectedDGFIP(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                tableName="DGFIP"
            />
        </>
    )
}

export default DGFIPData;