import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ITNS } from "../../../interface/tns";
import getTNSData from "../../../tools/forecast/getTNSData";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { formattedPrice } from "../../function/formattedPrice";

const TNSData = ({
    forecastVersion,
    forecastDates,
    selectedTNS,
    setSelectedTNS,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal,
    projectRegex,
    supplierRegex
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    selectedTNS: string[],
    setSelectedTNS: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setEditId: Function,
    setOpenModal: Function,
    projectRegex: string,
    supplierRegex: string
}) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [tnsData, setTnsData] = useState<any[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        getTNSData(params.id, forecastVersion, setTnsData, token, dispatch, setError);
    }, [forecastVersion, openModal, showDeleteDialog, selectedTNS]);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
            { name : "Fournisseur", field : "supplier"},
            { name : "Projet", field : "project"}
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: "custom-header",
        }
    })

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    let totalByMonth = {};

    useEffect(() => {
        const turnoverRows: GridRowsProp = tnsData
        .filter((tns) => tns.project && tns.project.toLowerCase().includes(projectRegex || ""))
        .filter((tns) => 
            (tns.supplier && tns.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
            (tns.fictifSupplier && tns.fictifSupplier.includes(supplierRegex || "")) ||
            (!supplierRegex && !tns.supplier && !tns.fictifSupplier)
        )
        .map((tns: ITNS) => {
            const turnoverRow: any = {
                id: tns._id,
                label: `\n${tns.label}\n\u000A`,
                supplier: `\n${tns.supplier?.name || tns.fictifSupplier || "Non précisé"}\n\u000A`,
                project: `\n${tns.project ||"Non précisé"}\n\u000A`
            };

            const monthExpenses = Object.fromEntries(
                Object.entries(tns.months).map(([key, value]) => {
                    if (typeof value === "object" && value !== null) {
                        const obj = value as { label: string; value: number };
                        return [obj.label, `\n${formattedPrice(obj.value)} €\n\u000A`];
                    }
                    return [key, `\n${value} €\n`];
                })
            );

            return { ...turnoverRow, ...monthExpenses };
        });
    
        totalByMonth = monthList.reduce((acc: any, item: any) => {
            acc[item.field] = tnsData.reduce((sum, tns) => {
                if(
                    (!tns.project || tns.project.toLowerCase().includes(projectRegex || "")) &&
                    (
                        (tns.supplier && tns.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
                        (tns.fictifSupplier && tns.fictifSupplier.includes(supplierRegex || "")) ||
                        (!supplierRegex && !tns.supplier && !tns.fictifSupplier)
                    )
                ){
                    const monthData = tns.months.find((month: any) => month.label === item.field);
                    return sum + (monthData ? monthData.value : 0);
                }
                else{
                    return sum;
                }
            }, 0);
            return acc;
        }, {});

        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );
        
        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            label: `\nTOTAL\n\u000A`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `\n${formattedPrice(value as number)} €\n\u000A`])
            )
        } : null;

        setRows(tnsData.length > 0 && totalRow ? [...turnoverRows, totalRow] : [...turnoverRows]);

        dispatch(setTotalByMonth("TNS", totalByMonth));
    }, [tnsData, projectRegex, supplierRegex])

    return (
        <>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                checkboxSelection
                rowSelectionModel={selectedTNS}
                onRowSelectionModelChange={(selectionModel) => { setSelectedTNS(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                tableName="TNS"
            />

        </>
    )
}

export default TNSData;