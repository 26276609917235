import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { ISubcontract, ISubcontractFormValues } from "../../../interface/subcontract";
import SubcontractInfosFields from "./SubcontractInfosFields";
import SubcontractMonthFields from "./SubcontractMonthFields";
import getBusinessDaysForMonth from "../../../tools/getBusinessDays";

const validationSchema = yup.object({
  label: yup.string().required("Le libellé est obligatoire"),
  agency: yup.string(),
  project: yup.string().required("Le projet est obligatoire !"),
  supplier: yup.string(),
  fictifSupplier: yup.string()
});

const AddUpdateSubcontract = ({
  subcontractId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  setShowConfirmDialog
}: {
  subcontractId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[],
  setShowConfirmDialog: Function
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [subcontract, setSubcontract] = useState<ISubcontract>();

  const getSubcontract = async () => {
    if (!token || !subcontractId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/subcontract/${subcontractId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSubcontract(syncResponse.data.message);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getSubcontract();
  }, []);

  useEffect(() => {
    console.log(subcontract);
  }, [subcontract]);

  const [error, setError] = useState("");

  const initialValues = useMemo(() => ({
      label: subcontract ? subcontract.label : "",
      agency: subcontract ? subcontract.agency : "Toutes",
      project: subcontract ? subcontract.project : "",
      supplier: subcontract && subcontract.supplier ? subcontract.supplier._id : "",
      fictifSupplier: subcontract && subcontract.fictifSupplier ? subcontract.fictifSupplier : "",
      typeFrais: subcontract ? subcontract.typeFrais : "", // Ajout du champ typeFrais
      months: subcontract ? subcontract.months
        : monthList.map((item: any) => ({
          label: item.field,
          openDays: -1,
          tjm: 0,
          amount: 0,
        })),
    }), [subcontract, monthList]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: ISubcontractFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, subcontractId);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
      const updatedMonths = formik.values.months.map((month, index) => {
        // Vérifie si c'est une nouvelle ligne (jamais modifiée)
        const isNewEntry = month.openDays === undefined || month.openDays === null || month.openDays === -1;
  
    
        // Si la ligne est nouvelle ou jamais modifiée ET que openDays vaut 0, on initialise
        if (isNewEntry) {
          const [monthName, yearStr] = month.label.split(/(\d+)/).filter(Boolean);
          const year = Number(yearStr);
    
          return {
            ...month,
            openDays: getBusinessDaysForMonth(year, monthName.trim()),
          };
        }
    
        // Si l'utilisateur a modifié (touché), on ne change rien (y compris s'il a mis 0)
        return month;
      });
    
      // Mise à jour uniquement si les valeurs sont réellement différentes
      if (JSON.stringify(updatedMonths) !== JSON.stringify(formik.values.months)) {
        formik.setFieldValue("months", updatedMonths, false); // `false` évite de marquer tout le formik comme touché
      }
    }, [formik.values.months]);
  

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <SubcontractInfosFields formik={formik} />
        <SubcontractMonthFields formik={formik} monthList={monthList} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => { setShowConfirmDialog(true) }}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
    </Box>
  )
}

export default AddUpdateSubcontract;