import { getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ISubcontract } from "../../../interface/subcontract";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { Typography } from "@mui/material";
import { formattedPrice } from "../../function/formattedPrice";

const COLUMNS_TO_DISPLAY = {
    infos: [
        { name: "Projet", field: "project" },
        { name: "Fournisseur", field: "supplier" },
        { name: "Libellé", field: "label" },
        { name: "Agence", field: "agency" },
    ]
}; 

const SubcontractData = ({
    forecastDates,
    selectedSubcontract,
    setSelectedSubcontract,
    setEditId,
    setOpenModal,
    type,
    subcontracts,
    projectRegex,
    supplierRegex
}: {
    forecastDates: string,
    selectedSubcontract: string[],
    setSelectedSubcontract: Function,
    setEditId: Function,
    setOpenModal: Function,
    type: string,
    subcontracts : ISubcontract[],
    projectRegex : string,
    supplierRegex : string
}) => {
    let dispatch = useDispatch();

    const monthList = getForecastMonthList(forecastDates);

    const [rows, setRows] = useState<GridRowsProp>([]);

    const columnsInfos: GridColDef[] = useMemo(() => COLUMNS_TO_DISPLAY.infos.map((column) => ({
        field: column.field,
        headerName: column.name,
        filterable: true,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
        headerClassName: "custom-header",
    })), []);
    
    const columnsMonth: GridColDef[] = useMemo(() => monthList.map((column) => ({
        field: column.field,
        headerName: column.name,
        minWidth: 160,
        headerClassName: "custom-header",
    })), [monthList]);
    
    const columns: GridColDef[] = useMemo(() => [...columnsInfos, ...columnsMonth], [columnsInfos, columnsMonth]); // Éviter le recalcul inutile

    const turnoverRows: GridRowsProp = useMemo(() => subcontracts
        .filter((subcontract) => 
            !subcontract.project || subcontract.project.toLowerCase().includes(projectRegex || "")
        )
        .filter((subcontract) => {
            return (
                (subcontract.supplier?.name && subcontract.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
                (subcontract.fictifSupplier && subcontract.fictifSupplier.toLowerCase().includes(supplierRegex || ""))
            );
        })        
        .map((subcontract) => {
            const turnoverRow: any = {
                id: subcontract._id,
                label: `\n${subcontract.label}\n\u000A`,
                agency: `\n${subcontract.agency}\n\u000A`,
                supplier: `\n${subcontract.supplier?.name || subcontract.fictifSupplier || "Non précisé"}\n\u000A`,
                project: `\n${subcontract.project || "Non précisé"}\n\u000A`
            };
        
            let monthExpenses;
            if (subcontract.typeFrais === "Régie") {
                monthExpenses = Object.fromEntries(
                    Object.entries(subcontract.months).map(([key, value]) => [value.label, 
                        `Nb JO : ${value.openDays}
                        TJM : ${value.tjm} €/JO
                        Total : ${value.openDays * value.tjm} €`
                    ])
                );
            }
            else {
                monthExpenses = Object.fromEntries(
                    Object.entries(subcontract.months).map(([key, value]) => [value.label, `\n${formattedPrice(value.amount)} €\n\u000A`])
                );
            }
        
            return { ...turnoverRow, ...monthExpenses };
    }), [subcontracts, projectRegex, supplierRegex]); 
    


    const totalByMonth = useMemo(() => monthList.reduce((acc: any, item: any) => {
        acc[item.field] = subcontracts.reduce((sum : number, subcontract : any) => {
            if (
                (!subcontract.project || subcontract.project.toLowerCase().includes(projectRegex || "")) && 
                (
                    (subcontract.supplier?.name && subcontract.supplier.name.toLowerCase().includes(supplierRegex || "")) ||
                    (subcontract.fictifSupplier && subcontract.fictifSupplier.toLowerCase().includes(supplierRegex || ""))
                )
            ){
                const matchingMonth = subcontract.months.find((month: any) => month.label === item.field);
                if (matchingMonth) {
                    if (subcontract.typeFrais === "Régie") {
                        return sum + (matchingMonth.openDays * matchingMonth.tjm || 0);
                    }
                    else {
                        return sum + (matchingMonth.amount || 0);
                    }
                }
            }
            else{
                return sum;
            }
        }, 0);
        return acc;
    }, {}), [monthList, subcontracts, projectRegex, supplierRegex]); // Ne se met à jour que si `subcontracts` change
    

    useEffect(() => {
        dispatch(setTotalByMonth(`Sous-traitance-${type}`, totalByMonth));
    }, [totalByMonth, dispatch]); // Ne se met à jour que si `totalByMonth` change
    

    useEffect(() => {
        const filteredTotalByMonth = Object.fromEntries(
            Object.entries(totalByMonth).filter(([_, value]) => value !== 0)
        );
        
        const totalRow = Object.keys(filteredTotalByMonth).length !== 0 ? {
            id: "total",
            label: `\nTOTAL ${type.toUpperCase()}\n\u000A`,
            ...Object.fromEntries(
                Object.entries(totalByMonth).map(([key, value]) => [key, `\n${formattedPrice(value as number)} €\n\u000A`])
            )
        } : null;

        setRows(subcontracts.length > 0 && totalRow ? [...turnoverRows, totalRow] : [...turnoverRows]);
    }, [totalByMonth]);

    return (
        <>
            <Typography variant="h5" sx={{ textAlign: "center", backgroundColor: "black", color: "white" }}>{type}</Typography>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                sx={{
                    '& .MuiDataGrid-columnHeader .sticky-column': {
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                    },
                    '& .sticky-column': {
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    maxHeight: "75vh",
                }}
                checkboxSelection
                rowSelectionModel={selectedSubcontract}
                onRowSelectionModelChange={(selectionModel) => { setSelectedSubcontract(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                tableName="Subcontracts"
            />
        </>
    )
}

export default SubcontractData;