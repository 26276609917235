import { Switch, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useEffect, useRef, useState } from "react";

type Month = {
    tjm: number;
    amount: number;
    openDays: number;
};

const TurnoverMonthFields = ({
    formik,
    monthList,
    tjm
}: {
    formik: any,
    monthList: { name: string, field: string }[],
    tjm: number
}) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const [typeChecked, setTypeChecked] = useState<boolean>(false);
    const [fixedTjm, setFixedTjm] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [tjmAmount, setTjmAmount] = useState<number>(tjm);

    useEffect(() => {
        if (formik.values.monthlyAmount) {
            setTjmAmount(formik.values.monthlyAmount);
            setAmount(formik.values.monthlyAmount);
        } else {
            setTjmAmount(0);
            setAmount(0);
        }
    }, [formik.values.monthlyAmount]);

    useEffect(() => {
        if (formik.values.type === "Régie") {
            setFixedTjm(!formik.values.months.every((month: Month) => month.tjm === formik.values.months[0].tjm));
        } else {
            setTypeChecked(!formik.values.months.every((month: Month) => month.amount === formik.values.months[0].amount));
        }
    }, [
        JSON.stringify(formik.values.months.map(({ tjm, amount } : {tjm: number, amount : number}) => ({ tjm, amount }))), 
        formik.values.type
    ]);

    const handleFixedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const parsedValue = parseFloat(value === "" ? "0" : value);

        if (formik.values.type === "Régie") {
            setTjmAmount(parsedValue);
        } else {
            setAmount(parsedValue);
        }

        formik.setFieldValue(
            "months",
            formik.values.months.map((month: Month) => ({
                ...month,
                [name]: parsedValue,
            }))
        );
    };

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const [_, index, field] = name.split("-");
        formik.setFieldValue(`months.${index}.${field}`, value === "" ? 0 : Number(value));
    }, [formik]);

    const handleSwitchChange = () => {
        if (formik.values.type === "Régie") {
            setFixedTjm(!fixedTjm)
            if (fixedTjm) {
                monthList.map((key, i) => {
                    formik.values.months[i].tjm = tjmAmount;
                })
            }
        }
        else {
            setTypeChecked(!typeChecked)
            if (typeChecked) {
                monthList.map((key, i) => {
                    formik.values.months[i].amount = amount;
                })
            }
        }
    }

    const textFieldAmountRefs = useRef<(HTMLInputElement | null)[]>([]);
    const textFieldTJMRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleClickDisabledAmount = (event : any) => {
        setTypeChecked(true);
        setFixedTjm(true);
        const index = event.currentTarget.dataset.index
		setTimeout(() => {
			textFieldTJMRefs.current[index]?.focus();
            textFieldAmountRefs.current[index]?.focus();
		}, 10); 
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "60%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: (formik.values.type === "Régie" ? !fixedTjm : !typeChecked) ? "bold" : "" }}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} constant
                </Typography>
                <Switch
                    {...label}
                    onClick={handleSwitchChange}
                    checked={(formik.values.type === "Régie" ? fixedTjm : typeChecked) || false}
                />
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: (formik.values.type === "Régie" ? fixedTjm : typeChecked) ? "bold" : "" }}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} variable
                </Typography>
            </Box>
            {(formik.values.type === "Régie" ? !fixedTjm : !typeChecked) && (
                <TextField
                    margin="normal"
                    required
                    type="number"
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal"
                    }}
                    id={formik.values.type === "Régie" ? "tjm" : "amount"}
                    label={formik.values.type === "Régie" ? "TJM Mensuel" : "Montant Mensuel"}
                    name={formik.values.type === "Régie" ? "tjm" : "amount"}
                    autoComplete={formik.values.type === "Régie" ? "tjm" : "amount"}
                    value={(formik.values.type === "Régie" ? tjmAmount : amount).toString()}
                    onChange={handleFixedChange}
                    autoFocus
                    sx={{ width: "40%", marginInline: "auto" }}
                />
            )}
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", columnGap: "30px" }}>
                {
                    monthList.map((month, index) => {
                        if (formik.values.type === "Régie") {
                            return (
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
                                    <Typography variant="subtitle1" sx={{ textAlign: "center" }}>{month.name}</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            type="number"
                                            id={`month-${index}-openDays`}
                                            label={"Jours ouvrés"}
                                            name={`month-${index}-openDays`}
                                            autoComplete={`month-${index}-openDays`}
                                            value={(formik.values.months[index].openDays).toString()}
                                            onChange={handleChange}
                                            autoFocus
                                            sx={{ width: "45%" }}
                                        />
                                        <Box sx={{width: "45%", position: "relative"}}>
                                            <TextField
                                                margin="normal"
                                                required
                                                type="number"
                                                id={`month-${index}-tjm`}
                                                label={"TJM"}
                                                name={`month-${index}-tjm`}
                                                autoComplete={`month-${index}-tjm`}
                                                value={(formik.values.months[index].tjm).toString()}
                                                onChange={handleChange}
                                                autoFocus
                                                disabled={!fixedTjm}
                                                sx={{ width: "100%" }}
                                                inputRef={(el) => (textFieldTJMRefs.current[index] = el)}
                                            />
                                            {!fixedTjm && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        backgroundColor: "transparent",
                                                    }}
                                                    onClick={handleClickDisabledAmount}
                                                    data-index={index}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        }
                        else {
                            return (
                                <Box sx={{width: "30%", position: "relative"}}>
                                    <TextField
                                        margin="normal"
                                        required
                                        type="number"
                                        id={`month-${index}-amount`}
                                        label={month.name}
                                        name={`month-${index}-amount`}
                                        autoComplete={`month-${index}-amount`}
                                        value={(formik.values.months[index].amount).toString()}
                                        onChange={handleChange}
                                        autoFocus
                                        disabled={!typeChecked}
                                        sx={{ width: "100%"}}
                                        inputRef={(el) => (textFieldAmountRefs.current[index] = el)}
                                    />
                                    {!typeChecked && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "transparent",
                                            }}
                                            onClick={handleClickDisabledAmount}
                                            data-index={index}
                                        />
                                    )}
                                </Box>
                            )
                        }
                    })
                }
            </Box>
        </Box>
    )
}

export default TurnoverMonthFields;